/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import SEO from "../components/SEO";
import Main from "../components/Main";
import Container from "../components/Container";
import Title from "../components/Title";
import SidebarRechtsgebiete from "../components/SidebarRechtsgebiete";

export default ({ data }) => {
  const e = data.markdownRemark;
  return (
    <Main>
      <SEO title="Page two" />

      <Container sx={{ display: "flex" }}>
        <SidebarRechtsgebiete />
        <div sx={{ mx: 3 }}>
          <Title
            titleName={e.frontmatter.name}
            titleSub=""
            titleLink=""
            titleURL=""
          />
          <ReactMarkdown>{e.rawMarkdownBody}</ReactMarkdown>
        </div>
      </Container>
    </Main>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: { regex: "/rechtsgebiet/" }
    ) {
      #html
      frontmatter {
        name
        # seo
      }
      rawMarkdownBody
    }
  }
`;

// /** @jsx jsx */
// import { Styled, jsx } from "theme-ui";

// import Main from "../components/Main";
// import SEO from "../components/SEO";
// import Title from "../components/Title";
// import Container from "../components/Container";

// export default () => (
//   <Main>
//     {/* TODO: Add SEO to page */}
//     <SEO title="Page" />

//     <Container>
//       <Title
//         titleName="Über ein Jahrhundert Erfahrung, damit Sie zu Ihrem Recht kommen."
//         titleSub="Unsere Rechtsgebiete"
//       />

//     </Container>
//   </Main>
// );
